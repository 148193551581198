<template>
  <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
    <SubHeader title="매장관리"></SubHeader>
    <div class="bg-body rounded-4 shadow-400 mb-3">
      <div class="my-2 pb-2">
        <div id="search_box" class="search_box rounded-4 zindex-1 pt-3 pb-3 border-bottom border-gray-relative-200 bg-body px-3">
          <select class="form-control thick bg-body border-gray-relative-300 mb-1" :class="{'text-gray-500':location==null, 'text-body':location!=null}" v-model="location" >
            <option class="text-body" value="">전체</option>
            <option class="text-body" v-for="(item,index) in selectList" :key="index" :value="item.value">{{item.text}}</option>
          </select>
          <div class="position-relative mb-1">
            <input class="form-control thick text-body bg-body border-gray-relative-300" type="text" placeholder="매장명" v-model="search_name">
            <div class="position-absolute end-0 top-50 translate-middle-y">
              <div class="px-3 small" v-if="search_name==''"><i class="far fa-search text-gray-500"></i></div>
              <div class="px-3 small" v-else @click="search_name =''"><i class="fas fa-times-circle text-gray-500"></i></div>
            </div>
          </div>
        </div>
          <ul class="mx-3 last-border-0" v-if="list&&list.length>0" style="max-height: 400px;overflow-y: auto;">
              <li class="py-3 flex-between-center border-bottom border-gray-relative-200" v-for="(item,index) in list" :key="index">
                <div class="d-flex flex-column">
                  <img :src="item.image_path" class="img-bg w-px-50 h-px-50 mb-1">
                  <span class="badge bg-main small">{{CheckArea(item.area)}}</span>
                </div>
                  
                  <div class="flex-between-center w-100">
                      <div class="d-flex flex-column border-end px-3 flex-grow-1">
                        <div class="flex-between-center">
                            <div class="d-flex flex-column">
                              <small class="text-gray-relative">{{item.name}}</small>
                              <small class="text-gray-relative-500">{{item.address}}</small>
                            </div>
                        </div>      
                      </div>
                      <!-- <span class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="ResetPassword(item)">
                          <i class="far fa-envelope text-muted"></i>
                      </span> -->
                      <span class="badge w-px-30 h-px-30 rounded-4 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="EditLocation(item)">
                          <i class="fal fa-edit text-muted"></i>
                      </span>
                      <span class="badge w-px-30 h-px-30 rounded-4 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border" @click="RemoveConfirmLocation(item)">
                          <i class="fal fa-trash text-muted"></i>
                      </span>
                  </div>
              </li>
          </ul>
          <div class="p-3 py-6 text-center text-gray-relative-500" v-else>
              <i class="fas fa-users fa-3x text-gray-relative-400 d-block my-3"></i>
              <p class="my-2 fs-px-13">매장 목록이 없어요.<br>매장을 추가해주세요.</p>
          </div>
      </div>
    </div>
    
    <div class="rounded-4 bg-body shadow-200">
      <h6 class="fw-bold p-3">매장 정보</h6>
          <hr class="m-0 border-gray-relative-400">

          <div class="p-3">
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">구분</label>
                  <select v-model="area" class="form-control" @change="ChangeArea()">
                    <option value="">선택해주세요.</option>
                    <option value="F">해외</option>
                    <option value="D">국내</option>
                  </select>
              </div>
              <div class="form-group mb-2" v-if="area =='D'">
                  <label class="small text-gray-relative-600">지역</label>
                  <select v-model="location" class="form-control">
                    <option value="">지역을 선택해주세요.</option>
                    <option :value="item.code" v-for="(item,index) in domestic_list" :key="index">{{ item.text }}</option>
                  </select>
              </div>
              <div class="form-group mb-2" v-if="area =='F'">
                  <label class="small text-gray-relative-600">국가</label>
                  <select v-model="location" class="form-control">
                    <option value="">국가를 선택해주세요.</option>
                    <option :value="item.code" v-for="(item,index) in foreign_list" :key="index">{{ item.text }}</option>
                  </select>
              </div>
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">상호</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매장 이름" v-model="name">
              </div>
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">주소</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매장 주소" v-model="address">
              </div>
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">상담 전화번호</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text"  v-model="tel" placeholder="상담 전화번호">
              </div>
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">매장 전화번호</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매장 전화번호" v-model="tel">
              </div>
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">매장 팩스번호</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매장 팩스번호" v-model="tel">
              </div>
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">사이트 주소</label>
                  <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="매장 사이트" v-model="site_url">
              </div>
              <div class="form-group mb-2">
                  <label class="small text-gray-relative-600">이미지</label>
                  <div v-if="image_path ==''">
                    <input class='form-control' type="file" @change="UploadFile"/>
                  </div>
                  <div v-if="image_path !=''" style="position:relative">
                    <div v-if="mode=='E'"><i class="fa fa-times text-danger" style="position:absolute;top:0px;right:10px;cursor:pointer" @click="RemoveUploadImage()"></i></div>
                    <img :src="image_path" style="height:100px">
                  </div>
              </div>
          </div>

      </div>

      <div @click="LocationAdd()" class="rounded-3 btn thick btn-main w-100 mt-4" v-if="mode =='N'">매장추가</div>
      <div class="border-top p-3 border-gray-relative-200" v-if="mode=='E'">
          <ul class="d-flex">
              <li class="rounded-3 p-2 text-center bg-gray-relative-200 flex-grow-1 me-1" @click="ResetInfo()">취소</li>
              <li class="rounded-3 p-2 text-center bg-main flex-grow-1 text-white"  @click="LocationEdit()">정보수정</li>
          </ul>
      </div>
      <Loading v-if="loading"/>
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Loading from '@/components/common/Loading.vue'
const CryptoJS = require("crypto-js");
import AWS from 'aws-sdk';
export default {
  components: {
      SubHeader,Loading
  },
  data(){
      return{
        loading : false,
        mode : 'N',
        list : [],
        t_code : '',
        Bucket : process.env.VUE_APP_AWSBUCKETNAME,
        region : process.env.VUE_APP_AWSBUCKETREGION,
        IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
        AWSURL: process.env.VUE_APP_AWSURL,
        area : '',
        name : '',
        address : '',
        tel : '',
        site_url : '',
        image_path : '',
        domestic_list: [],
        foreign_list : [],
        location : '',

        selectList:[
          {
            value:'국내',
            text:'국내'
          },
          {
            value:'해외',
            text:'해외'
          }
        ],
        search_name:''
      }
  },
  mounted(){
    this.GetLocationList();
    this.GetDomesticComboList()
    this.GetForeignComboList()
  },
  methods:{
    CheckArea(area){
      if(area =='F'){
        return "해외"
      }else if(area =='D'){
        return "국내"
      }else{
        return "기타"
      }
    },
    ChangeArea(){
      this.location = '';
    },
    UploadFile(e){
      const files = e.target.files;
			
      
			const file = files[0];
      const date = new Date();
      const timestamp = date.getTime();
      const fileName = timestamp+file.name;

      AWS.config.update({region: this.region,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
      var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.Bucket}});
      s3.upload({Key: fileName, Body : file, ACL:'public-read','ContentType':`${file.type}`}, (err)=>{
        if(err == null){
          
          const path = this.AWSURL + fileName;
          this.image_path = path
        }
      })
    },
    RemoveUploadImage(){
      if(this.mode =='E'){
        this.image_path = '';
      }
    },
    RemoveConfirmManager(item){
      this.t_code = item.code;
      this.$confirm(`관리자를 삭제하시겠습니까?`).then((result)=>{
        if( result.isOk){
          this.RemoveManager()
        }
      })
    },
    ResetInfo(){
      this.mode = 'N';
      this.image_path = '';
      this.name = '';
      this.area = '';
      this.address = '';
      this.location = '';
      this.tel = '';
      this.site_url = '';

    },
    ResetPassword(item){
      this.$confirm(`'${item.email} (${item.name})관리자'<br>비밀번호를 초기화하시겠습니까?`).then((result)=>{
        if( result.isOk ){
          this.t_code = item.code;
          this.ResetPasswordConfirm();
        }
      })
    },
    EditLocation(item){
      this.area = item.area;
      this.name = item.name;
      this.location = item.location;
      this.address = item.address;
      this.tel = item.tel;
      this.site_url = item.site_url;
      this.image_path = item.image_path;      

      this.t_code = item.code;
      this.mode = 'E';


    },
    ResetPasswordConfirm(){
      const code = this.t_code;
      const body = {code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/manager/ResetPassword',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("관리자 이메일로 초기화비밀번호를 전송하였습니다.").then(
                  ()=>{
                    this.ResetInfo();
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    RemoveManager(){
      
      const code = this.t_code;
      const body = {code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/manager/RemoveManager',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("관리자를 삭제하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                    this.GetManagerList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    LocationEdit(){
      const code = this.t_code;
      const area = this.area;
      const location = this.location;
      const name = this.name;
      const address = this.address;
      const tel = this.tel;
      const site_url = this.site_url;
      const image_path = this.image_path;


      const body = {area,name,address,tel,site_url,image_path,code,location};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/location/EditLocation',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("매장 정보를 수정하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                    this.GetLocationList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    LocationAdd(){
      
      const area = this.area;
      const name = this.name;
      const address = this.address;
      const location = this.location ;
      const tel = this.tel;
      const site_url = this.site_url;
      const image_path = this.image_path;

      const body = {area,name,address,tel,site_url,image_path,location};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.loading = true;
      this.$http.post('/shop/location/LocationAdd',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            this.loading = false;
            if(res.data.code =="200"){
              this.$alert("매장을 추가하였습니다.").then(
                  ()=>{
                    this.ResetInfo()
                      this.GetLocationList()
                  }
              )

            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    GetDomesticComboList(){
      
      const body = {};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.$http.post('/shop/manager/GetDomesticComboList',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            if(res.data.code =="200"){
              const e_body = res.data.body;
              const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res)
              this.domestic_list = body.list;
            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    GetForeignComboList(){
      
      const body = {};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.$http.post('/shop/manager/GetForeignComboList',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            if(res.data.code =="200"){
              const e_body = res.data.body;
              const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res)
              this.foreign_list = body.list;
            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    },
    GetLocationList(){
      
      const body = {};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
      this.$http.post('/shop/location/GetLocationList',{req}).then(
        (res) =>  { 
          if(res.status == 200){
            if(res.data.code =="200"){
              const e_body = res.data.body;
              const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res)
              this.list = body.list;
            }else if(res.data.code=='9999'){
              this.$store.dispatch('SETLOGOUT').then(
                  ()=>{
                      window.location.href="/signin";
                  }
              )
            }
          }
        }
      );
    }   
  }
}
</script>

<style>
.img-bg{
  object-fit: cover;
  border-radius: 12px;
}
.search_box{
  border-radius: 12px 12px 0 0 !important;
}
</style>